<template>
    <section class="content-wrapper">
      <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo-beekeeper.png">
                            </div>
                            <h4 class="text-center mt-4">Voulez vous modifier votre profil?</h4>
                            <h6 class="font-weight-light text-center">Veuillez remplir les champs</h6>
                            <b-form class="pt-3" @submit.prevent="handleSubmit">

                              <b-form-group id="pseudo" label="Nom d'utilisateur (pseudo)" label-for="pseudo">
                                <b-form-input
                                  id="pseudo"
                                  name="pseudo"
                                  v-model="pseudo"
                                  :state="$v.pseudo.$dirty ? !$v.pseudo.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="pseudo">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- email -->
                              <b-form-group id="email" label="email" label-for="email">
                                <b-form-input
                                type="email"
                                  id="email"
                                  name="email"
                                  v-model="email"
                                  :state="$v.email.$dirty ? !$v.email.$error : null"
                                  aria-describedby="email"
                                ></b-form-input>
                                <b-form-invalid-feedback id="email">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- password -->
                              <b-form-group id="password" label="Mot de passe" label-for="password">
                                <b-form-input
                                type="password"
                                  id="password"
                                  name="password"
                                  v-model="password"
                                  :state="$v.password.$dirty ? !$v.password.$error : null"
                                  aria-describedby="password"
                                ></b-form-input>
                                <b-form-invalid-feedback id="password">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- confirmation password -->
                              <b-form-group id="confirmPassword" label="Confirmer le mot de passe" label-for="confirmPassword">
                                <b-form-input
                                type="password"
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  v-model="confirmPassword"
                                  :state="$v.confirmPassword.$dirty ? !$v.confirmPassword.$error : null"
                                  aria-describedby="confirmPassword"
                                ></b-form-input>
                                <b-form-invalid-feedback id="confirmPassword">
                                  Les mots de passe ne sont pas identiques
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- firstname -->
                              <b-form-group id="firstname" label="Nom" label-for="firstname">
                                <b-form-input
                                  id="firstname"
                                  name="firstname"
                                  v-model="firstname"
                                  :state="$v.firstname.$dirty ? !$v.firstname.$error : null"
                                  aria-describedby="firstname"
                                ></b-form-input>
                                <b-form-invalid-feedback id="firstname">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- lastname -->
                              <b-form-group id="lastname" label="Prénom" label-for="lastname">
                                <b-form-input
                                  id="lastname"
                                  name="lastname"
                                  v-model="lastname"
                                  :state="$v.lastname.$dirty ? !$v.lastname.$error : null"
                                  aria-describedby="lastname"
                                ></b-form-input>
                                <b-form-invalid-feedback id="lastname">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- tel -->
                              <b-form-group id="tel" label="Numéro téléphone" label-for="tel">
                                <b-form-input
                                  id="tel"
                                  name="tel"
                                  v-model="tel"
                                  :state="$v.tel.$dirty ? !$v.tel.$error : null"
                                  aria-describedby="tel"
                                ></b-form-input>
                                <b-form-invalid-feedback id="tel">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <h4>Adresse</h4>
                               <!-- adresse Voie -->
                              <b-form-group id="WayAddress" label="Voie" label-for="wayAddress">
                                <b-form-input
                                  id="WayAddress"
                                  name="WayAddress"
                                  v-model="wayAddress"
                                  :state="$v.wayAddress.$dirty ? !$v.wayAddress.$error : null"
                                  aria-describedby="WayAddress"
                                ></b-form-input>
                                <b-form-invalid-feedback id="WayAddress">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- adresse ville -->
                              <b-form-group id="cityAddress" label="Ville" label-for="cityAddress">
                                <b-form-input
                                  id="cityAddress"
                                  name="cityAddress"
                                  v-model="cityAddress"
                                  :state="$v.cityAddress.$dirty ? !$v.cityAddress.$error : null"
                                  aria-describedby="cityAddress"
                                ></b-form-input>
                                <b-form-invalid-feedback id="cityAddress">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- Adresse code postal -->
                              <b-form-group id="postalCode" label="Code Postal" label-for="postalCode">
                                <b-form-input
                                  id="postalCode"
                                  name="postalCode"
                                  v-model="postalCode"
                                  :state="$v.postalCode.$dirty ? !$v.postalCode.$error : null"
                                  aria-describedby="postalCode"
                                ></b-form-input>
                                <b-form-invalid-feedback id="postalCode">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- adresse département  -->
                              <b-form-group id="departmentAddress" label="Département" label-for="departmentAddress">
                                <b-form-input
                                  id="departmentAddress"
                                  name="departmentAddress"
                                  v-model="departmentAddress"
                                  :state="$v.departmentAddress.$dirty ? !$v.departmentAddress.$error : null"
                                  aria-describedby="departmentAddress"
                                ></b-form-input>
                                <b-form-invalid-feedback id="departmentAddress">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- adresse pays  -->
                              <b-form-group id="countryAddress" label="Pays" label-for="countryAddress">
                                <b-form-input
                                  id="countryAddress"
                                  name="countryAddress"
                                  v-model="countryAddress"
                                  :state="$v.countryAddress.$dirty ? !$v.countryAddress.$error : null"
                                  aria-describedby="countryAddress"
                                ></b-form-input>
                                <b-form-invalid-feedback id="countryAddress">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                              
                              <div class="mt-3">
                                  <button class="btn btn-block  btn-lg font-weight-medium auth-form-btn" style="background:#0055a4; color:white">Modifier</button>
                              </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const axios= require('axios').default
import { validationMixin } from 'vuelidate'
import { required, email, sameAs} from 'vuelidate/lib/validators'
export default {
    name:'profilUpdate',
    mixins: [validationMixin],
    data () {
        return {
            ulid:'',
            ulidcompany:'',
            pseudo:'',
            email:'',
            password:'',
            confirmPassword:'',
            // roles:'',
            firstname:'',
            lastname:'',
            tel:'',
            wayAddress:'',
            cityAddress:'',
            departmentAddress:'',
            countryAddress:'',
            postalCode:'',
            datePublished:'',
            dateModified:''
        }
    },
    validations: {
        pseudo: {
            required
        },
        email: {
            required,
            email
        },
        password: {
            required
        },
        confirmPassword: {
          sameAs: sameAs('password')
        },
        firstname: {
            required
        },
        lastname: {
            required
        },
        tel: {
            required
        },
        wayAddress:{
          required
        },
        cityAddress: {
          required
        },
        departmentAddress: {
          required
        },
        countryAddress: {
          required
        },
        postalCode: {
          required
        }
    },
    methods: {
        handleSubmit() {
            this.$v.$touch()
        if (this.$v.$anyError) {
          return
        }
        console.log('envoie de la requête')
          axios.put(`myinfos/${this.ulid}`,
            {
                // ulidcompany: '01F1Z1PT53H19FAWCV0PG480RP',
                // apitoken:'',
                pseudo: this.pseudo,
                email: this.email,
                password: this.password,
                // roles: ['ROLE_USER_DASHBOARD'],
                firstname: this.firstname,
                lastname: this.lastname,
                tel: this.tel,
                address: {
                  Voie:this.wayAddress,
                  Ville:this.countryAddress,
                  Département:this.departmentAddress,
                  Pays:this.countryAddress,
                  PostalCode:this.postalCode
                },
                // datepublished: '2021-03-24 10:07:00',
                // datemodified: '2021-03-24 10:07:00'
            },{
              headers:{
                'X-AUTH-TOKEN':localStorage.getItem('token')
              }
            })
            .then(resPutInfo=> {
              console.log(resPutInfo),
              this.$snotify.success('Profil modifié avec succès', {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
            })
              this.$router.push({
                name:'profil'
              })
            })
            .catch(err=>{
              alert('Votre inscription comporte une erreur')
              console.log(err)})
        },

        // get infos
        myInfos() {
            axios.get('myinfos/', {
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            .then(resMyInfos => {
                this.ulid= resMyInfos.data.result.ulid
                this.pseudo= resMyInfos.data.result.pseudo
                this.email= resMyInfos.data.result.email
                this.password= resMyInfos.data.result.password
                this.firstname= resMyInfos.data.result.firstname
                this.lastname= resMyInfos.data.result.lastname
                this.tel= resMyInfos.data.result.tel
                this.wayAddress= resMyInfos.data.result.address.Voie
                this.cityAddress= resMyInfos.data.result.address.Ville
                this.departmentAddress= resMyInfos.data.result.address.Département
                this.countryAddress= resMyInfos.data.result.address.Pays
                this.postalCode= resMyInfos.data.result.address.Codepostal
                // this.datePublished= resMyInfos.data.result.datePublished
            })
            .catch(errMyInfos => console.log({errMyInfos}))
        }
    },
    mounted () {
    //   this.datePublished = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
      this.dateModified = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
      this.myInfos()

    }
}
</script>